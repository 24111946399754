import React, { Component } from 'react';
import styled from 'styled-components';
import { UiStateConsumer } from '../context/UiStateContext';
import { UserStateConsumer } from '../context/UserStateContext';
import ComponentStyles from '../brand-styles/components/Forms/SignUpForm.styles';
import ForgotPasswordService from '../services/forgotPassword.service';
import RegularButton from '../components/RegularButton';

const ResetPasswordStyles = styled.div`
  display: flex;

  .content {
    padding: 5%;
    flex: 1 40%;
  }

  .error {
    color: red;
  }

  .input-group {
    margin-bottom: 10px;
  }

  /* Dynamic brand styles */
  ${ComponentStyles}
`;

function validate(state) {
  const errors = {};
  const { password, confirmPassword } = state;

  if (!password) {
    errors.password = 'Password is required.';
  }
  if (!/(?=.*[a-z])/.test(password)) {
    errors.password =
      'Password must contain at least one (1) lowercase letter.';
  }
  if (!/(?=.*[A-Z])/.test(password)) {
    errors.password =
      'Password must contain at least one (1) uppercase letter.';
  }
  if (!/.*[0-9]/.test(password)) {
    errors.password = 'Password must contain at least one (1) number.';
  }
  if (password.length < 8) {
    errors.password =
      'Enter a password that is at least eight (8) characters in length.';
  }
  if (!confirmPassword) {
    errors.confirmPassword = 'Confirm Password is required.';
  }
  if (password !== confirmPassword) {
    errors.confirmPassword = 'Confirm Password must match Password.';
  }

  return errors;
}

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showForm: false,
      userIsNotValid: false,
      user: null,
      password: '',
      confirmPassword: '',
      errors: {},
      hasBeenSubmitted: false,
      success: false,
      isSubmitting: false
    };

    this.forgotPasswordService = new ForgotPasswordService();
  }

  componentDidMount() {
    let search = this.props.location.search;
    let params = new URLSearchParams(search);
    let id = params.get('token');

    if (id) {
      // here we set the user state to resetPassword so when they try ang login from any link
      this.props.userContext.updateUserState('resetPassword');
      this.forgotPasswordService.getForgotPassword(id).then(result => {
        if (result && result.email) {
          this.setState({
            user: result,
            showForm: true
          });
        } else {
          this.setState({
            userIsNotValid: true
          });
        }
      });
    } else {
      this.setState({
        userIsNotValid: true
      });
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState(
      {
        [name]: value
      },
      () => {
        if (this.state.hasBeenSubmitted) {
          const errors = validate(this.state);
          this.setState({
            errors
          });
        }
      }
    );
  };

  submit = () => {
    const currentState = this.state;
    const errors = validate(currentState);

    if (Object.keys(errors).length) {
      this.setState({
        errors: errors,
        hasBeenSubmitted: true
      });
    } else {
      this.setState({
        errors: errors,
        hasBeenSubmitted: true,
        isSubmitting: true
      });

      this.forgotPasswordService
        .resetPassword({
          email: this.state.user.email,
          password: this.state.password
        })
        .then(
          result => {
            if (result && result.status === false) {
              this.setState({
                errors: {
                  confirmPassword: result.message
                },
                isSubmitting: false
              });
            } else {
              this.setState({
                showForm: false,
                success: true,
                isSubmitting: false
              });

              this.forgotPasswordService.cleanupForgotPassword(
                this.state.user.id
              );
            }
          },
          error => {
            this.setState({
              errors: {
                confirmPassword: 'Error reseting password.'
              },
              isSubmitting: false
            });
          }
        );
    }
  };

  renderLoginButton = () => {
    return (
      <UserStateConsumer>
        {userStateContext => {
          return (
            <UiStateConsumer>
              {uiStateContext => (
                <RegularButton
                  onClick={() => {
                    uiStateContext.toggleModal('login');
                  }}
                >
                  Log In
                </RegularButton>
              )}
            </UiStateConsumer>
          );
        }}
      </UserStateConsumer>
    );
  };

  render() {
    return (
      <ResetPasswordStyles>
        <div className="content">
          <h3>Reset Password</h3>
          {this.state.showForm && (
            <div>
              <p>
                Password must be at least 8 characters long and contain 1
                lowercase, 1 uppercase and 1 number.{' '}
              </p>
              <div>
                <div className="input-group">
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    id="password"
                    value={this.state.password}
                    maxLength={255}
                    onChange={this.handleInputChange}
                  />
                </div>
                {this.state.errors.password && (
                  <p className="error">{this.state.errors.password}</p>
                )}
                <div className="input-group">
                  <input
                    type="password"
                    placeholder="Confirm Password"
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    maxLength={255}
                    onChange={this.handleInputChange}
                  />
                </div>
                {this.state.errors.confirmPassword && (
                  <p className="error">{this.state.errors.confirmPassword}</p>
                )}
                <RegularButton
                  type="button"
                  onClick={this.submit}
                  disabled={this.state.isSubmitting}
                >
                  Submit
                </RegularButton>
              </div>
            </div>
          )}
          {this.state.userIsNotValid && (
            <p>Not a valid reset password request.</p>
          )}
          {this.state.success && (
            <p>
              Success! Your password has been reset.
              <br /> <br />
              {this.renderLoginButton()}
            </p>
          )}
        </div>
      </ResetPasswordStyles>
    );
  }
}

export default ResetPasswordPage;
